import { Dispatch, FC, ReactNode, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import HTMLDisplay from '~/components/HTMLDisplay'
import { Button } from '~/core/ui/Button'
import { Dialog } from '~/core/ui/Dialog'

const TermAndConditionsDetail: FC<{
  children: (openModal?: Dispatch<SetStateAction<boolean>>) => ReactNode
  description?: string
}> = ({ children, description }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)
  return (
    <>
      {children(setOpen)}

      <Dialog
        className="min-w-[680px]"
        open={open}
        isPreventAutoFocusDialog
        label={`${t('careers:term_and_conditions:modal_title')}`}
        onOpenChange={setOpen}>
        <div className="max-h-[540px] overflow-y-auto">
          <HTMLDisplay content={description} />
        </div>
        <div className="flex justify-end pt-6">
          <Button
            size="sm"
            htmlType="submit"
            label={`${t('button:close')}`}
            onClick={() => setOpen(false)}
          />
        </div>
      </Dialog>
    </>
  )
}

export default TermAndConditionsDetail
