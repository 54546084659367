import { TFunction } from 'i18next'
import { z } from 'zod'
import { regexEmailValidation } from '~/core/utilities/common'
import {
  ACCEPTED_FILE_TYPES,
  MAX_FILE_SIZE,
  convertFileSizeToBytes
} from '~/lib/schema'
import { MAX_CHARACTERS_INTRODUCTION } from '~/core/constants/enum'

const schemaReferralForm = (t: TFunction) => {
  return z.object({
    fullName: z
      .string()
      .trim()
      .min(1, {
        message: `${t('form:requiredField')}`
      })
      .max(100, {
        message: `${t('form:field_max_number_required', {
          number: 100
        })}`
      }),
    email: z
      .string()
      .refine((value) => value.trim() !== '', {
        message: `${t('form:requiredField')}`
      })
      .refine((value) => regexEmailValidation.test(value), {
        message: `${t('form:invalid_email')}`
      }),
    phoneNumber: z.string().optional(),
    resumeFile: z
      .string({ required_error: `${t('form:requiredField')}` })
      .optional()
      .or(
        z
          .array(z.any())
          .length(1, {
            message: `${t('form:requiredField')}`
          })
          .refine(
            (files) =>
              files?.[0]?.size <=
              convertFileSizeToBytes({ size: MAX_FILE_SIZE }),
            `${t('referrals:referral_modal:form:wrong_upload_file_format')}`
          )
          .refine(
            (files) => ACCEPTED_FILE_TYPES.includes(files?.[0]?.type),
            `${t('referrals:referral_modal:form:wrong_upload_file_format')}`
          )
      ),
    introduction: z
      .string()
      .max(MAX_CHARACTERS_INTRODUCTION, {
        message: `${t('form:field_max_number_required', {
          number: MAX_CHARACTERS_INTRODUCTION
        })}`
      })
      .optional(),
    jobId: z
      .array(
        z
          .object({
            value: z.string(),
            supportingObj: z.object({
              name: z.string()
            })
          })
          .nullish()
          .refine((obj) => !!obj?.value, {
            message: `${t('form:requiredField')}`
          })
      )
      .length(1, {
        message: `${t('form:requiredField')}`
      })
  })
}

export default schemaReferralForm
