import { useTranslation } from 'react-i18next'
import configuration from '~/configuration'
import useContextGraphQL, {
  IResponseContextResult
} from '~/core/middleware/use-context-graphQL'
import { IPromiseSearchOption } from '~/core/ui/Select'
import { descriptionLocationSelectJob } from '../../jobs/utilities/common'
import QueryJobsApplicableList from '../graphql/query-job-applicable-list'
import { JobApplicableType } from '../types'

export const useReferralModal = () => {
  const { t } = useTranslation()
  const { clientGraphQL } = useContextGraphQL()
  const promiseJobApplicableOptions = (params = {} as IPromiseSearchOption) =>
    clientGraphQL
      .query(QueryJobsApplicableList, params)
      .toPromise()
      .then((result: IResponseContextResult<JobApplicableType>) => {
        if (result.error) {
          return {
            metadata: {
              totalCount: configuration.defaultAsyncLoadingOptions
            },
            collection: []
          }
        }

        const { jobsReferableList } = result.data
        const collection = jobsReferableList?.collection || []
        const metadata = jobsReferableList?.metadata || {}

        const cloneData = collection.map((job) => ({
          value: String(job?.id),
          supportingObj: {
            name: job?.title || '',
            shortName: job.title || '',
            description: job?.department?.name,
            descriptionHelpName: descriptionLocationSelectJob(
              job?.jobLocations,
              t
            )
          }
        }))

        return { metadata, collection: cloneData }
      })

  return { promiseJobApplicableOptions }
}
