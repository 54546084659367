import { FC, useCallback } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import pathConfiguration from 'src/configuration/path'
import { MAX_CHARACTERS_INTRODUCTION } from '~/core/constants/enum'
import useMutationGraphQL from '~/core/middleware/use-mutation-graphQL'
import { AsyncSingleSearchWithSelect } from '~/core/ui/AsyncSingleSearchWithSelect'
import { Button } from '~/core/ui/Button'
import { Dialog } from '~/core/ui/Dialog'
import { DynamicImportForm } from '~/core/ui/DynamicImportForm'
import { UploadFileDragAndDrop } from '~/core/ui/FileDragAndDrop'
import { IFormAction } from '~/core/ui/Form'
import { FormControlItem } from '~/core/ui/FormControlItem'
import { Input } from '~/core/ui/Input'
import { PhoneInput } from '~/core/ui/PhoneInput'
import { Textarea } from '~/core/ui/TextArea'
import { catchErrorFromGraphQL } from '~/core/utilities/catch-api-error'
import { useDetectCountryCodeFromTimeZone } from '~/lib/countries-mapping/hooks/use-detect-country-code-from-time-zone'
import CreateReferralMutation from '~/lib/features/referrals/graphql/create-referral-mutation'
import { useReferralModal } from '~/lib/features/referrals/hooks/use-referral-modal'
import schemaReferralForm from '~/lib/features/referrals/schema/schemaReferralForm'
import { ReferralFormType } from '~/lib/features/referrals/types'
import useBoundStore from '~/lib/store'

const ReferralModal: FC<{
  openReferralModal: boolean
  onClose: () => void
  defaultValue?: ReferralFormType
  callbackOnFinish?: () => Promise<void>
}> = ({ defaultValue, openReferralModal, onClose, callbackOnFinish }) => {
  const { t } = useTranslation()
  const setToast = useBoundStore((state) => state.setToast)
  const { trigger: triggerCreateReferral, isLoading: isFetching } =
    useMutationGraphQL({
      query: CreateReferralMutation
    })
  const { promiseJobApplicableOptions } = useReferralModal()
  const { countryCode } = useDetectCountryCodeFromTimeZone()

  const onSubmitForm: (
    data: ReferralFormType,
    formAction: IFormAction
  ) => Promise<any> = useCallback(
    (data, formAction) => {
      const formatData = {
        fullName: data.fullName,
        email: data.email,
        jobId: Number(data.jobId?.[0]?.value),
        resumeFile: data.resumeFile?.[0],
        ...(data?.phoneNumber ? { phoneNumber: data.phoneNumber } : {}),
        ...(data?.introduction ? { introduction: data.introduction } : {})
      }

      return triggerCreateReferral(formatData).then((result) => {
        if (result.error) {
          return catchErrorFromGraphQL({
            error: result.error,
            page: pathConfiguration.referral.list,
            formAction,
            setToast
          })
        }

        const { referralsCreate } = result?.data
        if (referralsCreate?.referral.id) {
          setToast({
            open: true,
            type: 'success',
            title: t('notification:referral:createdReferral')
          })
          onClose()
          callbackOnFinish && callbackOnFinish()
        }

        return true
      })
    },
    [triggerCreateReferral, setToast, onClose, callbackOnFinish]
  )

  return (
    <Dialog
      open={openReferralModal}
      size="sm"
      onOpenChange={onClose}
      isDivider={false}
      isPreventAutoFocusDialog={true}
      label={`${t('referrals:referral_modal:title')}`}
      description={`${t('referrals:referral_modal:description')}`}
      headingClassName="pb-4 tablet:pb-5">
      <DynamicImportForm
        isShowDebug={false}
        id="referral-form"
        className="w-full"
        defaultValue={defaultValue}
        schema={schemaReferralForm(t)}
        onSubmit={onSubmitForm}>
        {({ formState, control }) => {
          return (
            <>
              <div className="mb-4">
                <Controller
                  control={control}
                  name="fullName"
                  defaultValue={defaultValue?.fullName || ''}
                  render={({ field: { onChange, value } }) => (
                    <FormControlItem
                      labelRequired
                      label={`${t('referrals:referral_modal:full_name')}`}
                      destructive={
                        formState.errors && !!formState.errors?.fullName
                      }
                      destructiveText={
                        formState.errors &&
                        (formState.errors?.fullName?.message as string)
                      }>
                      <Input
                        placeholder={`${t(
                          'referrals:referral_modal:full_name_placeholder'
                        )}`}
                        size="sm"
                        autoFocus
                        onChange={onChange}
                        value={value}
                        destructive={
                          formState.errors && !!formState.errors?.fullName
                        }
                      />
                    </FormControlItem>
                  )}
                />
              </div>
              <div className="mb-4">
                <Controller
                  control={control}
                  name="email"
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <FormControlItem
                      labelRequired
                      label={`${t('label:placeholder:email')}`}
                      destructive={
                        formState.errors && !!formState.errors?.email
                      }
                      destructiveText={
                        formState.errors &&
                        (formState.errors?.email?.message as string)
                      }>
                      <Input
                        placeholder={`${t(
                          'referrals:referral_modal:email_placeholder'
                        )}`}
                        size="sm"
                        onChange={onChange}
                        value={value}
                        destructive={
                          formState.errors && !!formState.errors?.email
                        }
                      />
                    </FormControlItem>
                  )}
                />
              </div>

              <div className="mb-4">
                <Controller
                  control={control}
                  name="phoneNumber"
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <FormControlItem
                      destructive={
                        formState.errors && !!formState.errors.phoneNumber
                      }
                      destructiveText={
                        formState.errors
                          ? String(formState.errors.phoneNumber?.message)
                          : ''
                      }
                      label={`${t('form:phoneNumberProfileFieldLabel')}`}>
                      <PhoneInput
                        size="sm"
                        country={countryCode}
                        onChange={onChange}
                        value={value}
                        placeholder={`${t(
                          'form:phone_number_field_placeholder'
                        )}`}
                        destructive={
                          formState.errors && !!formState.errors.phoneNumber
                        }
                      />
                    </FormControlItem>
                  )}
                />
              </div>

              <div className="mb-4">
                <Controller
                  control={control}
                  name="resumeFile"
                  defaultValue={[]}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <FormControlItem
                        labelRequired
                        label={`${t('referrals:referral_modal:resume_cv')}`}
                        destructive={
                          formState.errors && !!formState.errors?.resumeFile
                        }
                        destructiveText={
                          formState.errors &&
                          (formState.errors?.resumeFile?.message as string)
                        }>
                        <UploadFileDragAndDrop
                          className="w-full"
                          configText={{
                            clickToUpload: `${t(
                              'label:dragAndDrop:clickToUpload'
                            )}`,
                            orDragAndDrop: `${t(
                              'label:dragAndDrop:orDragAndDrop'
                            )}`,
                            delete: `${t('tooltip:delete')}`,
                            tryAgain: `${t('tooltip:tryAgain')}`,
                            uploadANewFile: `${t('tooltip:uploadANewFile')}`
                          }}
                          dragNDropHelperText={`${t(
                            'referrals:referral_modal:drag_n_drop_type_files'
                          )}`}
                          maximumFiles={1}
                          files={
                            value
                              ? Array.isArray(value)
                                ? value
                                : [value]
                              : []
                          }
                          onChange={onChange}
                        />
                      </FormControlItem>
                    )
                  }}
                />
              </div>

              <div className="mb-4">
                <Controller
                  control={control}
                  name="introduction"
                  defaultValue={defaultValue?.introduction || ''}
                  render={({ field: { onChange, value } }) => (
                    <FormControlItem
                      label={`${t('referrals:referral_modal:introduction')}`}
                      destructive={
                        formState.errors && !!formState.errors?.introduction
                      }
                      destructiveText={
                        formState.errors &&
                        (formState.errors?.introduction?.message as string)
                      }>
                      <Textarea
                        size="sm"
                        onChange={onChange}
                        value={value}
                        limit={MAX_CHARACTERS_INTRODUCTION}
                        placeholder={`${t(
                          'referrals:referral_modal:introduction_placeholder'
                        )}`}
                        destructive={
                          formState.errors && !!formState.errors?.introduction
                        }
                      />
                    </FormControlItem>
                  )}
                />
              </div>

              <div className="mb-5">
                <Controller
                  control={control}
                  name="jobId"
                  defaultValue={[]}
                  render={({ field: { onChange, value } }) => (
                    <FormControlItem
                      labelRequired
                      label={`${t('referrals:referral_modal:job')}`}
                      destructive={
                        formState.errors && !!formState.errors?.jobId
                      }
                      destructiveText={
                        formState.errors &&
                        (formState.errors?.jobId?.message as string)
                      }>
                      <AsyncSingleSearchWithSelect
                        promiseOptions={promiseJobApplicableOptions}
                        size="sm"
                        onChange={(newValue) =>
                          onChange(newValue ? [newValue] : [])
                        }
                        placeholder={`${t('label:placeholder:select')}`}
                        menuPlacement="top"
                        value={value}
                        destructive={
                          formState.errors && !!formState.errors.jobId
                        }
                        configSelectOption={{
                          supportingText: [
                            'name',
                            'shortName',
                            'description',
                            'descriptionHelpName'
                          ]
                        }}
                        classNameOverride={{
                          loadingMessage: `${t('label:loading')}`,
                          noOptionsMessage: `${t('label:noOptions')}`
                        }}
                      />
                    </FormControlItem>
                  )}
                />
              </div>

              <div className="flex justify-end">
                <Button
                  size="sm"
                  type="secondary"
                  isDisabled={isFetching}
                  isLoading={isFetching}
                  label={`${t('button:cancel')}`}
                  onClick={onClose}
                />
                <Button
                  size="sm"
                  htmlType="submit"
                  isDisabled={isFetching}
                  isLoading={isFetching}
                  className="ml-4"
                  label={`${t('referrals:referral_modal:add_button')}`}
                />
              </div>
            </>
          )
        }}
      </DynamicImportForm>
    </Dialog>
  )
}

export default ReferralModal
